const farmacity = require('./farmacity.json');
const bk = require('./burger_king_ar.json');
const swissmedical = require('./swissmedical.json');
const omint = require('./omint.json');
const patagonia = require('./patagonia.json');
const activo = require('./activo.json');
const imperfectfoods = require('./imperfectfoods.json');
const heineken = require('./heineken.json');
const walmart = require('./walmart.json');
const iconn = require('./iconn.json');
const cemex = require('./cemex.json');
const tarjetaAmiga = require('./tarjeta-amiga.json');
const invista = require('./invista.json')
const simplicity = require('./simplicity.json')
const farmaciasdelahorro = require('./farmacias-del-ahorro.json')
const alsea = require('./alsea.json')
const danone = require('./danone.json')
const vips = require('./vips.json')
const burgerking = require('./burgerKing.json')
const thecheesecakefactory = require('./cheeseCakeFactory.json')
const italiannis = require('./italianis.json')
const chilis = require('./chilis.json')
const dominos = require('./dominos.json')
const pfchangs = require('./pfchangs.json')
const starbucks = require('./starbucks.json')
const nemak = require('./nemak.json')
const demo = require('./demo.json')
const sigma = require('./sigma.json')
const gardaworld = require('./garda.json')
const delmonte = require('./delmonte.json')
const oxxo = require('./oxxo.json')
const dish = require('./dish.json')
const demoUs = require('./demoUs.json')
const honeyBadger = require('./honeyBadger.json')
const hiltonDemo = require('./hiltonDemo.json')
const delmontemx = require('./delmontemx.json')
const centrodeoperacionesalsea = require('./centrodeoperacionesalsea.json')
const thehomedepot = require('./thehomedepot.json')
const oxxocedis = require('./oxxocedis.json')
const grupogaleria = require('./grupogaleria.json')
const grupolala = require('./grupolala.json')
const bodesa = require('./bodesa.json')
const kfc = require('./kfc')
const ihop = require('./ihop')
const carlsjr = require('./carlsjr')
const buffaloWildWings = require('./buffaloWildWings')
const alpura = require('./alpura')
const banorte = require('./banorte')
const farmaciasbenavides = require('./farmaciasbenavides')
const mercadolibre = require('./mercadolibre.json')
const vivaaerobus = require('./vivaaerobus.json')
const oxxogas = require('./oxxogas.json')
const ragasa = require('./ragasa.json')
const timhortons = require('./timhortons.json')
const gruporoga = require('./gruporoga.json')
const liverpool1 = require('./liverpool1.json');
const liverpool2 = require('./liverpool2.json');
const liverpool3 = require('./liverpool3.json');
const liverpool4 = require('./liverpool4.json');
// these keys are the endpoint and will load the corresponding file
// eg: when you go to /bk it will load the burger_king_ar file
module.exports = {
  farmacity,
  bk,
  swissmedical,
  omint,
  patagonia,
  activo,
  heineken,
  walmart,
  iconn,
  cemex,
  tarjetaAmiga,
  invista,
  simplicity,
  farmaciasdelahorro,
  alsea,
  danone,
  vips,
  burgerking,
  thecheesecakefactory,
  italiannis,
  chilis,
  dominos,
  pfchangs,
  starbucks,
  nemak,
  // sigma,
  gardaworld,
  demo,
  delmonte,
  oxxo,
  dish,
  demoUs,
  honeyBadger,
  hiltonDemo,
  delmontemx,
  centrodeoperacionesalsea,
  thehomedepot,
  oxxocedis,
  timhortons,
  grupogaleria,
  grupolala,
  bodesa,
  kfc,
  ihop,
  carlsjr,
  buffaloWildWings,
  alpura,
  banorte,
  farmaciasbenavides,
  mercadolibre,
  vivaaerobus,
  oxxogas,
  ragasa,
  gruporoga,
  liverpool1,
  liverpool2,
  liverpool3,
  liverpool4
};
