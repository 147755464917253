import React, { Component } from "react";
import "./JobBoardListing.css";
import "./sticky.css";
import "../../css/normalize.css";
import "../../css/grid.css";
import { injectIntl } from "react-intl";

import { Link } from "react-router-dom";
import DropdownFilters from "./DropdownFilters";
import WorkScheduleDropdownFilter from "./WorkScheduleDropdownFilter";

class Listings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      scrollingLock: false,
      copy: false,
      eref2: "",
      listingsIndex: 9,
      applyModalOpened: false,
      filtersModalOpened: false,
      applyPosition: {},
      selectedBrand: "",
      selectedLocation: "",
      selectedPositionMain: "",
      selectedPositionSub: "",
      selectedSchedule: "",
      selectedState: "",
      filteredListings: null,
      cityDropdown: false,
      stateDropdown: false,
      subPositionDropdown: false,
      brandDropdown: false,
      workScheduleDropdown: false
    };
    this.addMorePositions = this.addMorePositions.bind(this);
    this.openCloseModal = this.openCloseModal.bind(this);
    this.handlePositionMainFilterChange = this.handlePositionMainFilterChange.bind(
      this
    );
    this.handlePositionSubFilterChange = this.handlePositionSubFilterChange.bind(
      this
    );
    this.handleLocationFilterChange = this.handleLocationFilterChange.bind(
      this
    );
    this.handleBrandFilterChange = this.handleBrandFilterChange.bind(this);
    this.handleScheduleFilterChange = this.handleScheduleFilterChange.bind(
      this
    );
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.openCloseFiltersModal = this.openCloseFiltersModal.bind(this);
    this.handleMainFilterSubmit = this.handleMainFilterSubmit.bind(this)
    this.handleStateFilterChange = this.handleStateFilterChange.bind(this)
    this.handleCityDropdown = this.handleCityDropdown.bind(this)
    this.handleStateDropdown = this.handleStateDropdown.bind(this)
    this.handleSubPositionDropdown = this.handleSubPositionDropdown.bind(this)
    this.handleBrandDropdown = this.handleBrandDropdown.bind(this)
    this.handleWorkScheduleDropdown = this.handleWorkScheduleDropdown.bind(this)
    this.handleMainPositionInput = this.handleMainPositionInput.bind(this)
  }

  async componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };


  handlePositionMainFilterChange = (evt) => {
    this.setState({
      selectedPositionMain: evt.target.value.toLowerCase(),
    });
  };
  handleStateFilterChange = (state) => {
    if (state) {
      this.props.handleCityUpdates(state)
      this.setState({
        selectedState: state,
        stateDropdown: false
      });
    }
    else {
      this.setState({
        selectedState: state,
        selectedLocation: ""
      });
    }

  }
  handleMainFilterSubmit = () => {
    const { selectedPositionMain, selectedState, selectedLocation } = this.state
    const filteredListings = this.props.listings.filter((e) => {
      return (
        e.position.toLowerCase().includes(selectedPositionMain) &&
        e.state.includes(selectedState) && e.city.includes(selectedLocation)
      );
    });
    this.setState({
      filteredListings: filteredListings,
      listingsIndex: 9,
      selectedBrand: "",
      selectedPositionSub: "",
      selectedSchedule: "",
      cityDropdown: false,
      stateDropdown: false
    });
  }



  handlePositionSubFilterChange = (p) => {
    let {
      selectedBrand,
      selectedLocation,
      selectedPositionMain,
      selectedSchedule,
      selectedState
    } = this.state;
    this.setState({
      selectedPositionSub: p,
    });

    const filteredListings = this.props.listings.filter((e) => {
      let brand = selectedBrand && e.brand ? e.brand.includes(selectedBrand) : true;
      return (
        brand &&
        e.position.includes(p) &&
        e.position.toLowerCase().includes(selectedPositionMain) &&
        e.city.includes(selectedLocation) &&
        e.workSchedule.includes(selectedSchedule) &&
        e.state.includes(selectedState)
      );
    });
    this.setState({
      filteredListings: filteredListings,
      listingsIndex: 9,
      subPositionDropdown: false
    });
  };
  handleLocationFilterChange = (loc) => {
    this.setState({
      selectedLocation: loc,
      cityDropdown: false
    });
  };

  handleBrandFilterChange = (b) => {
    let {
      selectedLocation,
      selectedPositionMain,
      selectedPositionSub,
      selectedSchedule,
      selectedState
    } = this.state;
    this.setState({
      selectedBrand: b,
      brandDropdown: false
    });

    const filteredListings = this.props.listings.filter((e) => {
      let brand = b && e.brand ? e.brand.includes(b) : true;
      return (
        brand &&
        e.position.toLowerCase().includes(selectedPositionMain) &&
        e.position.includes(selectedPositionSub) &&
        e.city.includes(selectedLocation) &&
        e.workSchedule.includes(selectedSchedule) &&
        e.state.includes(selectedState)
      );
    });
    this.setState({
      filteredListings: filteredListings,
      listingsIndex: 9,
    });
  };

  handleScheduleFilterChange = (w) => {
    let {
      selectedBrand,
      selectedLocation,
      selectedPositionMain,
      selectedPositionSub,
      selectedState
    } = this.state;
    this.setState({
      selectedSchedule: w,
      workScheduleDropdown: false
    });

    const filteredListings = this.props.listings.filter((e) => {
      let brand = selectedBrand && e.brand ? e.brand.includes(selectedBrand) : true;
      return (
        brand &&
        e.position.toLowerCase().includes(selectedPositionMain) &&
        e.position.includes(selectedPositionSub) &&
        e.city.includes(selectedLocation) &&
        e.workSchedule.includes(w) &&
        e.state.includes(selectedState)
      );
    });
    this.setState({
      filteredListings: filteredListings,
      listingsIndex: 9,
    });
  };

  addMorePositions = () => {
    const { listings } = this.props;
    if (listings.length > this.state.listingsIndex + 9) {
      this.setState({
        listingsIndex: this.state.listingsIndex + 9,
      });
    } else {
      this.setState({
        listingsIndex: listings.length,
      });
    }
  };

  openCloseModal = (position) => {
    this.setState({
      applyModalOpened: !this.state.applyModalOpened,
      applyPosition: position,
    });
  };

  handleCityDropdown = () => {
    if (this.state.selectedState) {
      this.setState({
        cityDropdown: !this.state.cityDropdown,
        stateDropdown: false,
        brandDropdown: false,
        workScheduleDropdown: false,
        subPositionDropdown: false
      })
    }

  }

  handleMainPositionInput = () => {
    this.setState({
      cityDropdown: false,
      stateDropdown: false,
      brandDropdown: false,
      workScheduleDropdown: false,
      subPositionDropdown: false
    })
  }

  handleStateDropdown = () => {
    this.setState({
      stateDropdown: !this.state.stateDropdown,
      cityDropdown: false,
      brandDropdown: false,
      workScheduleDropdown: false,
      subPositionDropdown: false
    })

  }
  handleSubPositionDropdown = () => {
    this.setState({
      subPositionDropdown: !this.state.subPositionDropdown,
      cityDropdown: false,
      brandDropdown: false,
      workScheduleDropdown: false,
      stateDropdown: false,
      cityDropdown: false,
    })

  }
  handleBrandDropdown = () => {
    this.setState({
      brandDropdown: !this.state.brandDropdown,
      workScheduleDropdown: false,
      stateDropdown: false,
      cityDropdown: false,
      subPositionDropdown: false
    })

  }
  handleWorkScheduleDropdown = () => {
    this.setState({
      workScheduleDropdown: !this.state.workScheduleDropdown,
      cityDropdown: false,
      brandDropdown: false,
      stateDropdown: false,
      cityDropdown: false,
      subPositionDropdown: false
    })

  }

  openCloseFiltersModal = () => {
    this.setState({
      filtersModalOpened: !this.state.filtersModalOpened,
    });
  };

  render() {
    // si isLoading es = true significa que todavia no trajo los datos para mostrarlos
    let {
      company,
      listings,
      query,
      urlNavigation,
      handleWhatsappLink,
      handleSmsLink,
      handleFacebookLink,
      allStates,
      allBrands,
      allPositions,
      allLocations,
      allWorkSchedules,
      style
    } = this.props;
    let {
      listingsIndex,
      applyModalOpened,
      applyPosition,
      selectedBrand,
      selectedLocation,
      selectedPositionMain,
      selectedPositionSub,
      selectedSchedule,
      selectedState,
      filteredListings,
      width,
      filtersModalOpened,
      cityDropdown,
      stateDropdown,
      subPositionDropdown,
      brandDropdown,
      workScheduleDropdown
    } = this.state;



    let allFilteredListings = filteredListings ? filteredListings : listings;

    let showListings = allFilteredListings
      ? allFilteredListings.slice(0, listingsIndex)
      : [];

    const isMobile = width <= 700;

    let {
      addMorePositions,
      openCloseModal,
      handleBrandFilterChange,
      handleLocationFilterChange,
      handlePositionMainFilterChange,
      handlePositionSubFilterChange,
      handleScheduleFilterChange,
      openCloseFiltersModal,
      handleMainFilterSubmit,
      handleStateFilterChange,
      handleCityDropdown,
      handleStateDropdown,
      handleSubPositionDropdown,
      handleBrandDropdown,
      handleWorkScheduleDropdown,
      handleMainPositionInput
    } = this;

    return (
      <>
        <div className="section">
          <div className="main-filters-container" style={style && style.mainFiltersButtonBackgroundColor? {background: style.mainFiltersContainerBackgroundColor}:null}>
            <div className="main-title">{company.main_title}</div>
            <div className="filters-description">
              {company.filters_description}
            </div>
            {company.filters_description_2 ? (<div className="filters-description">
              {company.filters_description_2}
            </div>)
              :
              null}

            <div className="main-filters-section">
              <div className="main-position-filter" style={style.mainfFiltersInputBorder ? {border: style.mainfFiltersInputBorder} : null}>
                <img
                  alt="search-emoji"
                  src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/search-2.svg"
                />
                <input
                  className="main-position-filter-element"
                  placeholder="Nombre del puesto"
                  value={selectedPositionMain}
                  onChange={handlePositionMainFilterChange}
                  name="selectedPositionMain"
                  onClick={handleMainPositionInput}
                ></input>
              </div>
              <div className="dropdown-filter state-dropdown-filter" style={style.mainfFiltersInputBorder ? {border: style.mainfFiltersInputBorder} : null}>
                <div className="select-selected" onClick={handleStateDropdown}>
                  <div>
                    <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/pin-2.svg" />
                  </div>
                  <div className="city-dropdown-filter-element-title">
                    {`${!selectedState ? "Estado" : selectedState}`}
                  </div>
                  <div>
                    <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/arrow-down-2.svg" />
                  </div>
                </div>
                <div className={`select-items ${!stateDropdown ? "select-hide" : null}`}>
                  <div className="city-dropdown-filter-element-option"
                    onClick={() => handleStateFilterChange("")}
                  >

                  </div>
                  {allStates &&
                    allStates.map((state) => {
                      return (
                        <div key={state} className="city-dropdown-filter-element-option"
                          onClick={() => handleStateFilterChange(state)}
                        >
                          {state}
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className={`dropdown-filter city-dropdown-filter ${!selectedState ? `city-dropdown-filter-disabled` : null}`}
              style={style.mainfFiltersInputBorder ? {border: style.mainfFiltersInputBorder} : null}>
                <div className={`select-selected ${!selectedState ? "select-selected-disabled" : null}`} onClick={handleCityDropdown}>
                  <div>
                    <img src={!selectedState ? "https://emi-public.s3.amazonaws.com/static/walmart/Logos/disabled-pin.svg"
                      :
                      "https://emi-public.s3.amazonaws.com/static/walmart/Logos/pin-2.svg"} />
                  </div>
                  <div className={`city-dropdown-filter-element-title ${!selectedState ? `city-dropdown-filter-element-title-disabled` : null}`}>
                    {`${!selectedLocation ? "Ciudad/Alcaldía/Municipio" : selectedLocation}`}
                  </div>
                  <div>
                    <img src={!selectedState ? "https://emi-public.s3.amazonaws.com/static/walmart/Logos/arrow-down-disabled.svg"
                      :
                      "https://emi-public.s3.amazonaws.com/static/walmart/Logos/arrow-down-2.svg"} />
                  </div>
                </div>
                <div className={`select-items ${!cityDropdown || !selectedState ? "select-hide" : null}`}>
                  <div className="city-dropdown-filter-element-option"
                    onClick={() => handleLocationFilterChange("")}
                  >

                  </div>
                  {allLocations &&
                    allLocations.map((location) => {
                      return (
                        <div key={location} className="city-dropdown-filter-element-option"
                          onClick={() => handleLocationFilterChange(location)}
                        >
                          {location}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="main-position-filter-button">
                <button style={style && style.mainFiltersContainerBackgroundColor? {background: style.mainFiltersButtonBackgroundColor}:null} className="main-position-filter-button-element" onClick={handleMainFilterSubmit}>
                  {company.mainFiltersButtonImg ? <img
                  className="main-position-filter-button-image"
                  src={company.mainFiltersButtonImg}
                  onClick={handleMainFilterSubmit} /> : null}
                  Buscar posición</button>
              </div>
            </div>
          </div>
          <div className="section-listings">
            <div className="positions-section-title">
              {company.positions_container_title}
            </div>
            <div className="positions-section-subtitle">
              {company.positions_container_subtitle}
            </div>
            {!isMobile ? (
              <div className="positions-section-filters-container">
                {allBrands.length ? 
                <DropdownFilters
                  value={selectedBrand}
                  onChange={handleBrandFilterChange}
                  title="Marca"
                  elements={allBrands}
                  enableDropdown={handleBrandDropdown}
                  dropdownEnabled={brandDropdown}
                /> : null }
                <DropdownFilters
                  value={selectedPositionSub}
                  onChange={handlePositionSubFilterChange}
                  title="Posición"
                  elements={allPositions}
                  enableDropdown={handleSubPositionDropdown}
                  dropdownEnabled={subPositionDropdown}
                />
                <WorkScheduleDropdownFilter
                  value={selectedSchedule}
                  onChange={handleScheduleFilterChange}
                  title={"Jornada"}
                  elements={allWorkSchedules}
                  enableDropdown={handleWorkScheduleDropdown}
                  dropdownEnabled={workScheduleDropdown}
                />
              </div>
            ) : filtersModalOpened ? (
              <div className="mobile-filters-section">
                <div className="mobile-filters-container">
                  <div className="modal-apply-close">
                    <button
                      className="modal-apply-close-button"
                      onClick={openCloseFiltersModal}
                    >
                      <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/close.png" />
                    </button>
                  </div>
                  <div className="mobile-filters-title">
                    {" "}
                    Filtrar posiciones
                  </div>
                  <div className="positions-section-filters-container">
                    <DropdownFilters
                      value={selectedBrand}
                      onChange={handleBrandFilterChange}
                      title="Marca"
                      elements={allBrands}
                      enableDropdown={handleBrandDropdown}
                      dropdownEnabled={brandDropdown}
                    />
                    <DropdownFilters
                      value={selectedPositionSub}
                      onChange={handlePositionSubFilterChange}
                      title="Posición"
                      elements={allPositions}
                      enableDropdown={handleSubPositionDropdown}
                      dropdownEnabled={subPositionDropdown}
                    />
                    <WorkScheduleDropdownFilter
                      value={selectedSchedule}
                      onChange={handleScheduleFilterChange}
                      title={"Jornada"}
                      elements={allWorkSchedules}
                      enableDropdown={handleWorkScheduleDropdown}
                      dropdownEnabled={workScheduleDropdown}
                    />

                    <div className="mobile-filter-button-container">
                      <button
                        className="mobile-filter-button-modal"
                        onClick={openCloseFiltersModal}
                      >
                        <p className="mobile-filter-button-modal-text">
                          Aplicar filtros
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mobile-filter-button-container">
                <button
                  className="mobile-filter-button"
                  onClick={openCloseFiltersModal}
                >
                  <p className="mobile-filter-button-text">
                    <img
                      className="mobile-filter-button-emoji"
                      src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/filter.png"
                    />{" "}
                    Filtrar posiciones
                  </p>
                </button>
              </div>
            )}
            <div className="positions-container">
              {showListings && showListings.length > 0 ? (
                showListings.map((listing, index) => {
                  if (!listing) return;
                  let brands = listing.brand && listing.brand.map(brand => brand.slice(0, 1) + brand.slice(1).toLowerCase()) || []
                  return (
                    <div className="position-card" key={index}>
                      <div className="position-card-name-container">
                        <div className="position-card-name">
                          {listing.position}
                        </div>
                      </div>
                      <div className="position-card-location-brand-container">
                        <div className="position-card-brands">
                          {brands.join(", ")}
                        </div>
                        <div className="position-card-location">
                          {`${listing.city}, ${listing.state}`}
                        </div>

                      </div>
                      <div className="position-card-text-container">
                        <p className="position-card-text">
                          {listing.description && listing.description.length > 165
                            ? listing.description.slice(0, 165) + "..."
                            : listing.description}
                        </p>
                      </div>
                      <div className="position-card-links-container">
                        <Link
                          className="position-card-details"
                          to={{
                            pathname: `/${urlNavigation}/position/${listing.postingId}`,
                          }}
                        >
                          Ver detalles
                        </Link>
                        <button
                          className="position-card-apply"
                          onClick={() => openCloseModal(listing)}
                          style={style && style.cardButtonStyle? style.cardButtonStyle:null}
                        >
                          <div className="position-card-apply-text" style={style && style.cardButtonStyle && style.cardButtonStyle.textColor? {color: style.cardButtonStyle.textColor}:null}>
                            Aplicar
                          </div>
                          <div className="position-card-apply-channels-container">

                            <div className="position-card-apply-channel">
                              <img className="position-card-apply-channel-img" src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/whatsapp-2.svg" />
                            </div>
                            <div className="position-card-apply-channel">
                              <img className="position-card-apply-channel-img" src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/messenger-2.svg" />
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>Ninguna vacante coincide con tu búsqueda</div>
              )}
            </div>
            <div className="positions-load-container">
              <button
                className="positions-load-button"
                onClick={addMorePositions}
                disabled={
                  allFilteredListings && allFilteredListings.length > 0
                    ? !(allFilteredListings.length > listingsIndex)
                    : true
                }
              >
                Cargar más posiciones
              </button>
            </div>
          </div>
          {/* <!-- END LISTING --> */}
        </div>
        {applyModalOpened ? (
          <div className="modal-section">
            <div className="modal-apply-position">
              <div className="modal-apply-close">
                <button
                  className="modal-apply-close-button"
                  onClick={openCloseModal}
                >
                  <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/close.png" />
                </button>
              </div>
              {company.modalApplyImg ? (
              <div className="modal-image-container">
                <img src={company.modalApplyImg} />
              </div>
              ) : null}
              <div className="modal-apply-text-message">Vas a Aplicar</div>
              <div className="modal-apply-position-name">
                {applyPosition.position}
              </div>
              <div className="modal-apply-chat-message">
                Queremos ayudarte a aplicar más rapidamente a esta posición para
                esto tenemos un proceso diseñado para poder evaluar mejor tu
                perfil con Emi nuestro chatbot que te acompañará durante tu
                proceso.
              </div>
              <div className="modal-apply-select-channel-text">
                Selecciona el canal por el que deseas conversar con Emi
              </div>
              <div className="modal-apply-select-channel-buttons">
                <button
                  className="modal-apply-button"
                  style={{ background: "#128C7E" }}
                  onClick={() =>
                    handleWhatsappLink(company, query, applyPosition)
                  }
                >
                  <div>Aplicar con Whatsapp</div>
                  <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/whatsapp-white.svg" />
                </button>
                <button
                  className="modal-apply-button"
                  style={{ background: "#2F6DB3" }}
                  onClick={() =>
                    handleFacebookLink(company, query, applyPosition)
                  }
                >
                  <div>Aplicar con Facebook</div>
                  <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/facebook-messenger-white.svg" />
                </button>
              </div>
              <div className="modal-apply-link-details">
                <Link
                  className="position-card-details"
                  to={{
                    pathname: `/${urlNavigation}/position/${applyPosition.postingId}`,
                  }}
                >
                  Ver detalles
                </Link>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default injectIntl(Listings);
