import React, { Component, Fragment } from "react";
import "./Footer.css";
import emi_logo_footer from "../../img/emi_logo_footer.svg";
import {
  ModalsPoliticaDeConfidencialidad,
  ModalsPreguntasFrecuentes,
} from "../Modals";
import { handleScrollListing } from "../Buttons/handleScroll";
import { injectIntl } from "react-intl";
import messages from "./messages";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ModalPdc: false,
      ModalPf: false,
    };
    this.OpenModalPdc = this.OpenModalPdc.bind(this);
    this.OpenModalPf = this.OpenModalPf.bind(this);
  }

  OpenModalPdc = () => {
    this.setState({ ModalPdc: true });
  };
  CloseModalPdc = () => {
    this.setState({ ModalPdc: false });
  };

  OpenModalPf = () => {
    this.setState({ ModalPf: true });
  };
  CloseModalPf = () => {
    this.setState({ ModalPf: false });
  };

  handleLink = (link) => {
    // window.location.href = link;
    window.open(
      link,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
  render() {
    const { formatMessage, locale } = this.props.intl;
    const { company, style } = this.props;
    let { ModalPdc, ModalPf } = this.state;
    let { OpenModalPdc, OpenModalPf, CloseModalPdc, CloseModalPf } = this;
    return (
      <Fragment>
        <div className="footer-container">
            <div className="logo-footer-container" style={style && style.footerBackgroundColor ? { background: style.footerBackgroundColor } : null}>
              {company.company_logo_footer ? (
                <>
                <div className="footer-client-logo-container-first"></div>
              <div className="footer-client-logo-container">
                <img
                  src={company.company_logo_footer}
                  alt="Company Name"
                  className="footer-client-logo"
                />

              </div></>
              ) : null}
              {company.socialNetworkLinks ? <div className="footer-client-logo-social-container">
                <div className="footer-client-logo-social-title">
                  Encuéntranos en:
                </div>
                <div className="footer-client-logo-social-logos">
                  {company.socialNetworkLinks.tikTok ? <div className="footer-client-logo-social-logo">
                    <a
                      href={company.socialNetworkLinks.tikTok}
                      target="_blank"
                    >
                      <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/tiktok.svg" />
                    </a>
                  </div> : null}
                  {company.socialNetworkLinks.facebook ? <div className="footer-client-logo-social-logo">
                    <a
                      href={company.socialNetworkLinks.facebook}
                      target="_blank"
                    >
                      <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/facebook.svg" />
                    </a>
                  </div> : null}
                  {company.socialNetworkLinks.instagram ? <div className="footer-client-logo-social-logo">
                    <a
                      href={company.socialNetworkLinks.instagram}
                      target="_blank"
                    >
                      <img src="https://emi-public.s3.amazonaws.com/static/walmart/Logos/instagram.svg" />
                    </a>
                  </div> : null}
                </div>
              </div> : null}
            </div>
          


          <div className="emi-footer-container">
            <div className="emi-footer-made">
              Made with ❤️ by{" "}
              <img
                className="emi-logo-footer"
                src="https://emi-public.s3.amazonaws.com/logos/Emi_Logotipo_negativo+1.svg"
                alt="Emi Logo"
              />
            </div>
            <div>Emi Labs © 2022 - Todos los derechos reservados</div>
            <div>
              <a
                href="https://www.emilabs.ai/privacy"
                target="_blank"
                className="footer-privacy-policy-link"
              >
                Políticas de Privacidad
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(Footer);
